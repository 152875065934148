
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "@/layouts/LayoutDefault.vue";
import TitleText from "@/components/TitleText.vue";

@Component<any>({
  metaInfo() {
      return {
        title: "404 Not Found",
        meta: [{
          vmid: 'description',
          name: 'description',
          content: "The page you are looking for cannot be found.",
        }]
    }
  },
  components: {
    LayoutDefault,
    TitleText
  }
})
export default class Error404 extends Vue {
  private img = {
    src: require("@/assets/404.png"),
    alt: "Page not found"
  };
  private titleText1 = {
    customClass: "pb-5",
    title: "Something doesn't look quite right...",
    subTitle: "The page is no longer available"
  };
}
